import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { sendGAEvent } from "gatsby-plugin-analytics"

import config from "../../config/website"

import Layout from "../components/layout"
import shortcodes from "../components/shortcodes"

import { pageSlug } from "../lib/page-slug-full"
import { breakpoints } from "../parameters/theme"

const shortcodeComps = {
  ...shortcodes,
  a: shortcodes.MDXLink,
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  .section-share-buttons {
    clear: both;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    max-width: 768px;
    flex-wrap: wrap;

    &::before {
      clear: both;
      content: "";
      display: block;
      height: 2em;
      margin-top: 0;
      width: 100%;
    }

    .button {
      color: #ffffff;
      display: block;
      padding: 1em;
      text-align: center;
      text-decoration: none;
      width: 25%;

      &.facebook {
        background: #3b5998;
      }
      &.twitter {
        background: #1bb2e9;
      }
      &.pinterest {
        background: #c8232c;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;

    .section-share-buttons {
      &::before {
        height: 1em;
      }

      .button {
        margin-top: 1em;
        width: 100%;
      }
    }
  }
`

const ArticleTemplate = ({
  data: { contentfulArticle: article, allContentfulAsset },
  location,
  pageContext,
}) => {
  const headerBackground = article.backgroundImage
    ? article.backgroundImage.fluid
    : {}

  const url = `${config.url}${pageSlug(article)}`

  const sendGA = network => {
    sendGAEvent({
      event: "Social Media",
      value: network,
      url: url,
    })
  }

  return (
    <Layout
      location={location}
      pageSlug={pageSlug(article)}
      pageType="article"
      pageClassName="post"
      pageContext={{ category: article.category }}
      title={article.title}
      header={article.header}
      description={article.excerpt ? article.excerpt.excerpt : ""}
      headerBackground={headerBackground}
      author={{
        createdAt: article.creationDate || article.createdAt,
        ...article.author,
      }}
      nodeData={article}
    >
      <MDXProvider components={shortcodeComps}>
        <Container>
          {article.childContentfulMdx && article.childContentfulMdx.childMdx ? (
            <MDXRenderer>
              {article.childContentfulMdx.childMdx.body}
            </MDXRenderer>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: article.body ? article.body.body : "",
              }}
            ></div>
          )}
          <aside className="section-share-buttons">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              className="button facebook"
              title="Share this on Facebook"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendGA("facebook")
              }}
            >
              Share
            </a>
            <a
              href={`https://twitter.com/share?original_referer=${url}&amp;text=${article.title}&amp;url=${url}&amp;via=athlegan`}
              className="button twitter"
              title="Share this on Twitter"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendGA("twitter")
              }}
            >
              Tweet
            </a>
            <a
              href={`https://pinterest.com/pin/create/link/?url=${url}&amp;media=&amp;description=${article.title}`}
              className="button pinterest"
              title="Share this on Pinterest"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendGA("pinterest")
              }}
            >
              Pin
            </a>
          </aside>
          <shortcodes.RelatedArticles slug={article.slug} />
        </Container>
      </MDXProvider>
    </Layout>
  )
}

export const query = graphql`
  query GetArticleByID($contentfulId: String) {
    contentfulArticle(contentful_id: { eq: $contentfulId }) {
      author {
        slug
        name
        tagline
        avatarImage {
          fluid(maxWidth: 665, quality: 100) {
            src
          }
        }
      }

      category

      createdAt(formatString: "DD MMM, Y")
      creationDate(formatString: "DD MMM, Y")
      header
      slug
      title
      excerpt {
        excerpt
      }
      updatedAt(formatString: "DD MMM, Y")
      backgroundImage {
        fluid(quality: 100, maxWidth: 768) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      titleImage {
        title
        fluid(quality: 100) {
          src
        }
      }

      childContentfulMdx {
        childMdx {
          body
        }
      }
      body {
        body
      }
      internal {
        type
      }
    }
  }
`

export default ArticleTemplate
